<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">Assign Role</h3>
          <!-- <span class="text-muted font-weight-bold font-size-sm mt-1" >Update your personal informaiton</span> -->
        </div>
        <div class="card-toolbar">
          <button
            type="reset"
            class="btn btn-success mr-2"
            @click="save()"
            ref="kt_save_changes"
          >
            Save Changes
          </button>
          <!-- <button type="reset" class="btn btn-secondary" @click="reset()">
            Reset
          </button> -->
        </div>
      </div>
      <!--end::Header-->
      <div class="card card-custom gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 py-5">
          <!-- <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark">Agents Stats</span>
            <span class="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>
          </h3> -->
          <div class="card-toolbar">
            <a
              href="#"
              class="btn btn-info font-weight-bolder font-size-sm"
              @click.prevent="openDialogAddRole"
              >Add Role</a
            >
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body py-0">
          <!--begin::Table-->
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_2"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-0" style="min-width: 100px">
                    <span class="text-primary">#</span>
                  </th>
                  <th style="min-width: 120px">
                    <span class="text-primary">Name</span>
                  </th>
                  <th style="min-width: 150px">
                    <span class="text-primary">Code</span>
                  </th>
                  <th style="min-width: 150px">
                    <span class="text-primary">Note</span>
                  </th>
                  <th style="min-width: 150px">
                    <span class="text-primary">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(role, i) in roles">
                  <tr :key="i">
                    <td class="pl-0">
                      <a
                        href="#"
                        class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                        >{{ i + 1 }}</a
                      >
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ role.name }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ role.code }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ role.note }}</span
                      >
                    </td>
                    <td>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <button
                            class="btn btn-icon btn-light-danger btn-sm ml-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="btnLockRole(i)"
                          >
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <v-icon color="">mdi-lock</v-icon>
                            </span>
                          </button>
                        </template>
                        <span>Lock</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
                <template v-if="!roles.length">
                  <tr>
                    <td style="text-align: center" colspan="4">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >No data</span
                      >
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogAddRole" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Add Role</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddRole = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label style="font-size: 16px">Roles</label>
                  <v-autocomplete
                    v-model="role_ids"
                    :disabled="isUpdating"
                    :items="role_tags"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label=""
                    item-text="name.en"
                    item-value="id"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeRoleTag(data.item)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnAddRole"
            >
              Add
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogAddRole = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
// import Swal from "sweetalert2";
export default {
  name: "AssignRole",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: ["data_user"],
  data() {
    return {
      roles: [],
      role_tags: [],
      role_ids: null,
      isUpdating: false,
      is_call_api: false,
      dialogAddRole: false,
    };
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    data_user: function (val) {
      if (val.roles != undefined) {
        this.setInputData();
      }
    },
  },
  created() {
    this.getAllRole();
    if (this.data_user.roles != undefined) {
      this.setInputData();
    }
  },
  methods: {
    setInputData() {
      this.roles = this.data_user.roles;
      for (let indexRole = 0; indexRole < this.roles.length; indexRole++) {
        for (let indexTag = 0; indexTag < this.role_tags.length; indexTag++) {
          if (this.role_tags[indexTag].id == this.roles[indexRole].id) {
            this.role_tags.splice(indexTag, 1);
          }
        }
      }
    },
    save() {
      this.$emit("submitForm");
    },
    async getAllRole() {
      let seft = this;
      await ApiService.get("prep-app/role?limit=-1").then(function (response) {
        if (response.status == 200) {
          seft.role_tags = response.data;
        }
      });
    },
    openDialogAddRole() {
      this.role_ids = null;
      this.dialogAddRole = true;
    },
    removeRoleTag(item) {
      let index = this.role_ids.indexOf(item.id);
      if (index >= 0) this.role_ids.splice(index, 1);
    },
    btnAddRole() {
      for (let indexTag = 0; indexTag < this.role_tags.length; indexTag++) {
        for (let indexId = 0; indexId < this.role_ids.length; indexId++) {
          if (this.role_tags[indexTag].id == this.role_ids[indexId]) {
            this.roles.push(this.role_tags[indexTag]);
            this.role_tags.splice(indexTag, 1);
          }
        }
      }
      this.$emit("setDataForm", {
        filed: "role_ids",
        value: this.roles.map((e) => {
          return e.id;
        }),
      });
      this.dialogAddRole = false;
    },
    btnLockRole(indexRole) {
      this.role_tags.push(this.roles[indexRole]);
      this.roles.splice(indexRole, 1);
      this.$emit("setDataForm", {
        filed: "role_ids",
        value: this.roles.map((e) => {
          return e.id;
        }),
      });
    },
  },
};
</script>

<style lang="css" scoped>
/* .text-primary {
  color: #3699FF !important;
} */
</style>
