<template>
  <v-app>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254" v-if="user_id === undefined">
        Add User
      </h1>
      <h1 class="mt-5 mb-5" style="color: #3f4254" v-else>Edit User</h1>
    </div>
    <div class="d-flex flex-row">
      <div
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
        id="kt_profile_aside"
      >
        <div class="card card-custom card-stretch">
          <div class="card-body pt-4">
            <div class="d-flex align-items-center">
              <div
                class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
              >
                <div
                  class="symbol-label"
                  :style="{ 'background-image': `url(${photo})` }"
                ></div>
                <i class="symbol-badge bg-success"></i>
              </div>
              <div>
                <a
                  class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                  >{{ name }}</a
                >
                <div class="text-muted">{{ email }}</div>
              </div>
            </div>
            <!--end::User-->
            <!--begin::Contact-->
            <div class="py-9">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Username:</span>
                <a class="text-muted text-hover-primary">{{ user_name }}</a>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">{{ phone }}</span>
              </div>
            </div>
            <!--end::Contact-->
            <!--begin::Nav-->
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
              role="tablist"
            >
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4 active"
                  @click="setActiveTab"
                  data-tab="0"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/General/User.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg font-weight-bold"
                    >Account Information</span
                  >
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  data-tab="1"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Communication/Shield-user.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg font-weight-bold"
                    >Roles</span
                  >
                  <span class="navi-label">
                    <span
                      class="label label-light-danger label-rounded font-weight-bold"
                      >{{ role_ids.length }}</span
                    >
                  </span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  data-tab="2"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Communication/Shield-user.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg font-weight-bold"
                    >Academic Profile</span
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--begin::Content-->
      <div class="flex-row-fluid ml-lg-8">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <Information
              @setDataForm="setDataForm"
              :data_user="data_user"
              @submitForm="submit"
            ></Information>
          </b-tab>

          <b-tab>
            <AssignRole
              @setDataForm="setDataForm"
              :data_user="data_user"
              @submitForm="submit"
            ></AssignRole>
          </b-tab>
          <b-tab>
            <AcademicProfile
              @setDataForm="setDataForm"
              :data_user="data_user"
              @submitForm="submit"
            ></AcademicProfile>
          </b-tab>
        </b-tabs>
      </div>
      <!--end::Content-->
    </div>
  </v-app>
</template>

<script>
import Information from "@/view/pages/users/profile/UserInformation";
import AssignRole from "@/view/pages/users/profile/AssignRole";
import AcademicProfile from "@/view/pages/users/profile/AcademicProfile";
import ApiService from "@/service/api.service";

export default {
  name: "UserDetail",
  components: {
    Information,
    AssignRole,
    AcademicProfile,
  },
  props: ["user_id"],
  data() {
    return {
      tabIndex: 0,
      avatar_data: null,
      avatar_file: null,
      email: "",
      phone: "",
      user_name: "",
      name: "",
      password: "",
      confirm_password: "",
      role_ids: [],
      data_user: {},
      user_profile_id: null,
      year_of_starting_teaching: null,
      ielts_score: null,
      qualification: null,
      short_description: null,
      description: null,
      avatar_web_data: null,
      avatar_web_file: null,
      color: "",
      display_name: "",
      ielts_image_file: null,
      ielts_image_data: null,
      product_category_ids: [],
    };
  },
  computed: {
    default_photo: {
      get() {
        return window.location.origin + "/media/users/blank.png";
      },
    },
    photo() {
      return this.avatar_data == null ? this.default_photo : this.avatar_data;
    },
  },
  async created() {
    await this.setInputData();
  },
  methods: {
    async setInputData() {
      if (this.user_id !== undefined) {
        let vm = this;
        let response = await ApiService.get("prep-app/user/" + this.user_id);
        if (response.status === 200) {
          vm.user_name = response.data.user_name;
          vm.name = response.data.name;
          vm.email = response.data.email;
          vm.phone = response.data.phone;
          vm.avatar_data = response.data.avatar;
          vm.data_user = response.data;
          vm.role_ids = response.data.roles.map((e) => {
            return e.id;
          });
          if (response.data.profiles.length > 0) {
            vm.user_profile_id = response.data.profiles[0].id;
            vm.ielts_score = JSON.parse(response.data.profiles[0].content);
            vm.year_of_starting_teaching =
              response.data.profiles[0].year_of_starting_teaching;
            vm.qualification = response.data.profiles[0].qualification;
            vm.short_description = response.data.profiles[0].short_description;
            vm.description = response.data.profiles[0].description;
            vm.avatar_web_data = response.data.profiles[0].avatar;
            vm.color = response.data.profiles[0].color;
            vm.display_name = response.data.profiles[0].display_name;
            vm.ielts_image_data = response.data.profiles[0].ielts_image;
          } else {
            vm.year_of_starting_teaching = null;
            vm.ielts_score = {
              listening: null,
              reading: null,
              writing: null,
              speaking: null,
              overall: null,
            };
            vm.qualification = null;
            vm.short_description = null;
            vm.description = [];
            vm.user_profile_id = null;
            vm.avatar_web_data = null;
            vm.avatar_web_file = null;
            vm.color = null;
            vm.display_name = null;
            vm.ielts_image_file = null;
            vm.ielts_image_data = null;
          }
        }
      }
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }
      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));
      // set current active tab
      target.classList.add("active");
    },
    setDataForm(data) {
      if (data.filed === "name") {
        this.name = data.value;
      } else if (data.filed === "user_name") {
        this.user_name = data.value;
      } else if (data.filed === "phone") {
        this.phone = data.value;
      } else if (data.filed === "email") {
        this.email = data.value;
      } else if (data.filed === "password") {
        this.password = data.value;
      } else if (data.filed === "confirm_password") {
        this.confirm_password = data.value;
      } else if (data.filed === "role_ids") {
        this.role_ids = data.value;
      } else if (data.filed === "avatar_file") {
        this.avatar_file = data.value == null ? null : data.value;
      } else if (data.filed === "avatar_data") {
        this.avatar_data =
          data.value == null
            ? window.location.origin + "/media/users/blank.png"
            : data.value;
      } else if (data.filed === "year_of_starting_teaching") {
        this.year_of_starting_teaching = data.value;
      } else if (data.filed === "ielts_score") {
        this.ielts_score = data.value;
      } else if (data.filed === "qualification") {
        this.qualification = data.value;
      } else if (data.filed === "short_description") {
        this.short_description = data.value;
      } else if (data.filed === "description") {
        this.description = data.value;
      } else if (data.filed === "avatar_web_file") {
        this.avatar_web_file = data.value;
      } else if (data.filed === "color") {
        this.color = data.value;
      } else if (data.filed === "display_name") {
        this.display_name = data.value;
      } else if (data.filed === "ielts_image_file") {
        this.ielts_image_file = data.value;
      } else if (data.filed === "product_category_ids") {
        this.product_category_ids = data.value;
      }
    },
    submit() {
      if (this.user_name === "" || this.user_name === undefined) {
        this.$toasted.error("User name is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (/\s/.test(this.user_name)) {
        this.$toasted.error("Username không được có khoảng trắng !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.name === "" || this.name === undefined) {
        this.$toasted.error("Name is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.email === "" || this.email === undefined) {
        this.$toasted.error("Email is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.user_id === undefined) {
        if (this.password === "" || this.password === undefined) {
          this.$toasted.error("Password is required !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          return;
        }
      }
      if (/\s/.test(this.password)) {
        this.$toasted.error("Password không được có khoảng trắng !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.password !== this.confirm_password) {
        this.$toasted.error(
          "Your password and confirmation password do not match !!",
          { theme: "toasted-primary", position: "top-right", duration: 4000 }
        );
        return;
      }
      let formData = new FormData();
      if (this.user_name !== "") formData.append("username", this.user_name);
      if (this.name !== "") formData.append("name", this.name);
      if (this.email !== "") formData.append("email", this.email);
      if (
        !(this.phone == null || this.phone === "" || this.phone === undefined)
      )
        formData.append("phone", this.phone);
      if (this.password !== "") formData.append("password", this.password);
      if (this.role_ids.length) formData.append("role_ids", this.role_ids);
      if (this.avatar_file != null) formData.append("avatar", this.avatar_file);

      if (this.avatar_web_file != null)
        formData.append("avatar_web_file", this.avatar_web_file);
      if (this.ielts_image_file != null)
        formData.append("ielts_image_file", this.ielts_image_file);
      let academic_data = {
        year_of_starting_teaching: this.year_of_starting_teaching,
        ielts_score: this.ielts_score,
        qualification: this.qualification,
        short_description: this.short_description,
        description: this.description,
        color: this.color,
        display_name: this.display_name,
      };

      if (this.user_profile_id != null)
        formData.append("user_profile_id", this.user_profile_id);
      formData.append("academic_data", JSON.stringify(academic_data));
      if (this.product_category_ids.length) {
        formData.append("product_category_ids", this.product_category_ids);
      } else {
        formData.append("product_category_ids", []);
      }
      this.$emit("submitForm", formData);
    },
  },
};
</script>
