<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Academic Profile
        </h3>
        <!-- <span class="text-muted font-weight-bold font-size-sm mt-1" >Update your personal informaiton</span> -->
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <!-- <button type="reset" class="btn btn-secondary" @click="reset()">
          Reset
        </button> -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Ảnh hiển thị trên web</label
          >
          <div class="col-lg-9 col-xl-7">
            <v-row>
              <v-col cols="12" class="pt-0 pr-0">
                <!--                <ImageCompression :done="getFiles"></ImageCompression>-->
                <b-form-file
                  @change="onChange"
                  placeholder="Chọn tệp tin hoặc cancel để xóa"
                  accept="image/*"
                ></b-form-file>
                <div style="width: 50%" v-if="avatar_web_data !== null">
                  <b-img
                    class="mt-1"
                    rounded
                    height="200px"
                    :src="avatar_web_data"
                  ></b-img>
                </div>
              </v-col>
              <v-col cols="6" class="pt-0 pr-0 d-flex">
                <label class="text-right font-weight-bold">Color:</label>
                <v-color-picker
                  class="ma-2"
                  mode="hexa"
                  v-model="color_input"
                ></v-color-picker>
              </v-col>
              <v-col cols="6" class="pt-0 pr-0 d-flex">
                <label class="text-right font-weight-bold">Display name:</label>
                <input
                  class="form-control form-control-lg form-control-solid ml-1"
                  type="text"
                  v-model="display_name_input"
                />
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Year of starting teaching</label
          >
          <div class="col-lg-9 col-xl-7">
            <input
              v-model="year_of_starting_teaching_input"
              class="form-control form-control-lg form-control-solid"
              type="number"
            />
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Ielts score</label
          >
          <div class="col-lg-9 col-xl-7 pb-0">
            <v-row>
              <v-col cols="3" class="pt-0 pr-0 d-flex">
                <label class="text-right font-weight-bold">Listening:</label>
                <input
                  class="form-control form-control-lg form-control-solid ml-1"
                  type="number"
                  v-model="ielts_score.listening"
                />
              </v-col>
              <v-col cols="3" class="pt-0 pr-0 d-flex">
                <label class="text-right font-weight-bold">Reading:</label>
                <input
                  class="form-control form-control-lg form-control-solid ml-1"
                  type="number"
                  v-model="ielts_score.reading"
                />
              </v-col>
              <v-col cols="3" class="pt-0 pr-0 d-flex">
                <label class="text-right font-weight-bold">Writing:</label>
                <input
                  class="form-control form-control-lg form-control-solid ml-1"
                  type="number"
                  v-model="ielts_score.writing"
                />
              </v-col>
              <v-col cols="3" class="pt-0 pr-0 d-flex">
                <label class="text-right font-weight-bold">Speaking:</label>
                <input
                  class="form-control form-control-lg form-control-solid ml-1"
                  type="number"
                  v-model="ielts_score.speaking"
                />
              </v-col>
              <v-col cols="3" class="pt-0 pr-0 d-flex">
                <label class="text-right font-weight-bold"
                  >Overall Score:</label
                >
                <input
                  class="form-control form-control-lg form-control-solid ml-1"
                  type="number"
                  v-model="ielts_score.overall"
                />
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Upload bảng điểm IELTS</label
          >
          <div class="col-lg-9 col-xl-7 pb-0">
            <ImageCompression :done="getIeltsImage"></ImageCompression>
            <div style="width: 50%" v-if="ielts_image_data !== null">
              <b-img
                class="mt-1"
                rounded
                height="200px"
                :src="ielts_image_data"
              ></b-img>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Qualifications</label
          >
          <div class="col-lg-9 col-xl-7">
            <div class="input-group input-group-lg input-group-solid">
              <input
                v-model="qualification_input"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Short description</label
          >
          <div class="col-lg-9 col-xl-7">
            <div class="input-group input-group-lg input-group-solid">
              <input
                v-model="short_description_input"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Description</label
          >
          <div class="col-lg-9 col-xl-7">
            <div class="input-group input-group-lg input-group-solid">
              <!--              <Editor v-model="description_input"></Editor>-->
              <v-card flat style="width: 100%">
                <v-row>
                  <v-col cols="7" class="p-0">
                    <v-textarea
                      outlined
                      auto-grow
                      dense
                      rows="1"
                      class="ml-4 p-0 mt-4 text-body-1"
                      v-model="item_description_input"
                    ></v-textarea>
                  </v-col>
                  <v-btn
                    color="cyan"
                    class="ma-2 mt-4 font-weight-bold"
                    outlined
                    @click="addItemDes"
                  >
                    <v-icon small left>mdi-check</v-icon> Lưu
                  </v-btn>
                  <v-btn
                    color="#E53935"
                    class="mt-4 font-weight-bold"
                    outlined
                    @click="
                      () => {
                        this.item_description_input = '';
                        this.index_item_des = null;
                      }
                    "
                  >
                    <v-icon small left>mdi-close</v-icon> Hủy nhập
                  </v-btn>
                </v-row>
                <ul
                  style="background-color: #f3f6f9; border-radius: 5px"
                  class="pt-2 pb-2"
                  v-if="description_input.length > 0"
                >
                  <template v-for="(description, i) in description_input">
                    <li class="d-flex mt-4" :key="i">
                      <v-btn
                        color="#FFAB00"
                        outlined
                        small
                        @click="editItemDes(i)"
                      >
                        <v-icon small>mdi-file-document-edit-outline</v-icon>
                      </v-btn>
                      <v-btn
                        color="#D84315"
                        class="ml-1"
                        outlined
                        small
                        @click="deleteItemDes(i)"
                      >
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                      <p class="text-body-1 mb-0 ml-2 mr-2 text-justify">
                        {{ description }}
                      </p>
                    </li>
                  </template>
                </ul>
                <div
                  v-if="description_input.length <= 0"
                  style="
                    background-color: #f3f6f9;
                    border-radius: 5px;
                    min-height: 30px;
                    padding-top: 5px;
                  "
                >
                  <p style="text-align: center">No data</p>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "AcademicProfile",
  components: {
    // Editor: () => import('@/components/ckeditor5/Editor.vue'),
    ImageCompression: () => import("@/components/image/ImageCompress"),
  },
  props: ["data_user"],
  data() {
    return {
      user_profile_id: null,
      year_of_starting_teaching_input: null,
      ielts_score: {
        listening: null,
        reading: null,
        writing: null,
        speaking: null,
        overall: null,
      },
      qualification_input: "",
      short_description_input: "",
      description_input: [],
      avatar_web_data: null,
      avatar_web_file: null,
      color_input: "#808080",
      display_name_input: null,
      comments: ["lorem1", "lorem2", "lorem3"],
      item_description_input: "",
      index_item_des: null,
      ielts_image_data: null,
      ielts_image_file: null,
    };
  },
  watch: {
    year_of_starting_teaching_input(val) {
      this.$emit("setDataForm", {
        filed: "year_of_starting_teaching",
        value: val,
      });
    },
    ielts_score: {
      handler(val) {
        this.$emit("setDataForm", { filed: "ielts_score", value: val });
      },
      deep: true,
    },
    qualification_input(val) {
      this.$emit("setDataForm", { filed: "qualification", value: val });
    },
    short_description_input(val) {
      this.$emit("setDataForm", { filed: "short_description", value: val });
    },
    description_input(val) {
      this.$emit("setDataForm", { filed: "description", value: val });
    },
    avatar_web_file(val) {
      this.$emit("setDataForm", { filed: "avatar_web_file", value: val });
    },
    color_input(val) {
      this.$emit("setDataForm", { filed: "color", value: val });
    },
    display_name_input(val) {
      this.$emit("setDataForm", { filed: "display_name", value: val });
    },
    ielts_image_file(val) {
      this.$emit("setDataForm", { filed: "ielts_image_file", value: val });
    },
    data_user: {
      handler(val) {
        if (val.profiles !== undefined) {
          this.setInputData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.setDefaultData();
  },
  methods: {
    setInputData() {
      if (this.data_user.profiles.length > 0) {
        this.user_profile_id = this.data_user.profiles[0].id;
        this.ielts_score = JSON.parse(this.data_user.profiles[0].content);
        this.year_of_starting_teaching_input =
          this.data_user.profiles[0].year_of_starting_teaching;
        this.qualification_input = this.data_user.profiles[0].qualification;
        this.short_description_input =
          this.data_user.profiles[0].short_description;
        this.description_input = this.data_user.profiles[0].description;
        this.avatar_web_data = this.data_user.profiles[0].avatar;
        this.avatar_web_file = null;
        this.color_input =
          this.data_user.profiles[0].color === null
            ? "#808080"
            : this.data_user.profiles[0].color;
        this.display_name_input = this.data_user.profiles[0].display_name;
        this.ielts_image_data = this.data_user.profiles[0].ielts_image;
        this.ielts_image_file = null;
      }
    },
    setDefaultData() {
      this.user_profile_id = null;
      this.year_of_starting_teaching_input = null;
      this.ielts_score = {
        listening: null,
        reading: null,
        writing: null,
        speaking: null,
        overall: null,
      };
      this.qualification_input = null;
      this.short_description_input = null;
      this.description_input = [];
      this.avatar_web_data = null;
      this.avatar_web_file = null;
      this.color_input = "#808080";
      this.display_name_input = null;
      this.ielts_image_data = null;
      this.ielts_image_file = null;
    },
    save() {
      this.$emit("submitForm");
    },
    getFiles(obj) {
      this.avatar_web_data = obj.base64;
      this.avatar_web_file = obj.file;
    },
    getIeltsImage(obj) {
      this.ielts_image_data = obj.base64;
      this.ielts_image_file = obj.file;
    },
    addItemDes() {
      if (
        this.item_description_input === "" ||
        this.item_description_input == null
      ) {
        this.$toasted.error("Không được bỏ trống !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
      if (this.index_item_des == null) {
        this.description_input.push(this.item_description_input);
      } else {
        this.description_input[this.index_item_des] =
          this.item_description_input;
      }
      this.index_item_des = null;
      this.item_description_input = "";
    },
    editItemDes(index) {
      this.index_item_des = index;
      this.item_description_input = this.description_input[index];
    },
    deleteItemDes(index) {
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.description_input.splice(index, 1);
        }
      });
    },
    onChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.avatar_web_data = null;
        vm.avatar_web_file = null;
        return;
      }
      vm.avatar_web_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.avatar_web_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="css">
.ck.ck-editor {
  width: 100%;
}
</style>
